<template>
  <div class="container" id="content">
    <TableBox
      ref="tableBox"
      :tableHeader="tableHeader"
      :rowBtns="rowBtns"
      :addBtn=false
      request="/pro09/inout/pageOutws"
    ></TableBox>

    <UpdataModal
      ref="updata"
      :showFlag="showFlag"
      :messageBox="messageBox"
      :formTitle="formTitle"
      @close="closeModal"
      @success="refresh"
      :record="addForm"
    ></UpdataModal>
    <!-- 二维码 -->
    <el-dialog :modal="false" title="二维码" :visible.sync="codeShow" width="20%">
      <div v-loading="loadingCode" id="qrcode" ref="qrCodeUrl"></div>
      <!--       <span slot="footer" class="dialog-footer">
                <el-button size="small" type="primary"
                >下载</el-button
                >
            </span>-->
    </el-dialog>
  </div>
</template>
<script>
import UpdataModal from "./modal/updata.vue";
import TableBox from "@/components/lxw/TableBox/index.vue";
import QRCode from "qrcodejs2";

export default {
  name: "lawsAReg",
  components: { UpdataModal, TableBox },
  data() {
    return {
      loading: false,
      ids: 0,
      addForm: {},

      tableHeader: [
        /*{
          label: "合同编号",
          key: "id",
        },*/
        {
          label: "产品名称",
          key: "cpmc",
        },
        {
          label: "产品规格",
          key: "spec",
        },
        {
          label: "出库数量",
          key: "capacity",
        },       
        {
          label: "出库日期",
          key: "ckrq",
        },
        /*{
          label: "出库价格",
          key: "price",
        },*/
        {
          label: "入库价格",
          key: "inwsPrice",
        },
        {
          label: "入库日期",
          key: "rkrq",
        },
        /*{
          label: "合同期限（天）",
          key: "htqx",
        }, */
        {
          label: "到期日期",
          key: "dqrq",
        },       
        {
          label: "果农名称",
          key: "gnmc",
        },       
        {
          label: "果农电话",
          key: "gndh",
        },       
        {
          label: "果农地址",
          key: "gndz",
        },          
       
      ],
      rowBtns: [
        {
          label: "二维码",
          type: "primary",
          size: "small",
          handler: (row) => {
            this.getErweima(row);
          },
        },
        {
          label: "删除",
          type: "danger",
          size: "small",
          icon: "el-icon-delete",
          handler: (row) => {
            this.deletes(row.id);
          },
        },
      ],

      formTitle: "",
      messageBox: false,
      showFlag: false,
      token: "",
      filesList: [],
      sourCodeUrl:"http://cloud.half-half.cn/coldRecord",
      codeShow:false,
      loadingCode: true,
    };
  },
  mounted() {
    this.token = JSON.parse(window.localStorage.getItem("token"));
  },
  methods: {

    //二维码
    getErweima(data) {
      console.log("二维码：",data)
      let url =`${data.uuid}`;
      console.log(url)
      this.urls = url;
      this.$nextTick(() => {
        this.codeShow = true;
        setTimeout(() => {
          this.$refs.qrCodeUrl.innerHTML = "";
          var qrcode = new QRCode(this.$refs.qrCodeUrl, {
            text: url,
            width: 200,
            height: 200,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H
          });
        }, 200);
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.addForm = JSON.parse(JSON.stringify(row));
      this.messageBox = true;
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/pro09/inout/deleteOutws/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.$refs.tableBox.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },

    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;

      this.$nextTick(() => {
        this.addForm = {
          // title: 'biaoti'
        };
        this.$refs.updata.resetForm();
      });
    },

    refresh() {
      this.messageBox = false;
      this.$refs.tableBox.getList();
    },

    closeModal() {
      this.messageBox = false;
    },
  },
};
</script>

<style scoped>
</style>
